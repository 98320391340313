nav {
    background: rgba(0,0,0,0.9);
    padding:20px;
    display: block;
    /* justify-content: space-between; */
    color: #fff;
    position: absolute;
    width: 100%;
    z-index: 99;
}

.menu-icon {
    display: inline !important;
}

.fas {
    cursor: pointer;
}

.menu-icon .fa-times {
    transform: rotate(360deg);
    transition: all .2s ease-in-out;
    display:none;
}

.menu-icon .fa-bars {
    transform: rotate(180deg);
    transition: all .2s ease-in-out;
    display:none;
}

.logo {
    display: inline !important;
}

.logo a {
    font-weight: 600;
    font-size: 25px;
    color: tomato;
    line-height: 1rem;
}

.logo font {
   color: #fff 
}

.logo a:hover,
.logo a.active {
    background-color: tomato;
    border-radius: 2px;
    color: #fff;
    transition: all 0.25s ease-in-out;
    text-decoration: none;
}


.menu-list {
    list-style-type: none;
    display:flex;
    justify-content: space-between;
    align-items: center;
    /* position: relative; */
    margin-bottom: 0px;
}

.menu-list li a {
    text-decoration: none;
    color: #fff;
    font-weight: 300;
    padding: 0px 5px;
    margin-left: 20px;
    /* position: relative; */
    /* display: inline-block; */
}

.menu-list li:last-child a {
    font-weight: 300;
    /* padding:5px 10px; */
    background-color:#F05D43;
    transition: all 0.5s ease-in-out;
    /* margin-left: 20px; */
    border-radius: 2px;
}

.menu-list li a:hover,
.nemu-list li a.active {
    background-color: #fff;
    border-radius: 2px;
    color: #000;
    transition: all 0.5s ease-in-out;
}

@media screen and (max-width:800px) and (min-width: 613px) {
    /* .logo a{
        font-size: 20px;
    } */
    .menu-list li a {
        font-size: 14px;
        margin-left: 10px;
    }
    .menu-icon .fa-times {
        transform: rotate(360deg);
        transition: all .2s ease-in-out;
        display: none;
    }
    .menu-icon .fa-bars {
        transform: rotate(180deg);
        transition: all .2s ease-in-out;
        display: none;
    }
}

@media screen and (max-width:612px) {
    .menu-icon {
        display: inline !important;
        text-align: right !important;
    }
    .menu-list {
        flex-direction: column;
        /* display: block; */
        width: 100%;
        position: absolute;
        background-color: rgba(0,0,0,0.7);
        padding: 20px;
        right: 0;
        top: 64px;
        transition: all 0.5s ease-in-out;
    }
    .menu-list li {
        margin-bottom: 20px;
        width: 100%;
        text-align: center;
    }
    .menu-list li:last-child {
        margin-bottom: 0px;
        
    }
    .menu-list li a, .menu-list li:last-child {
        width: 100px;
        display: block;
        margin: auto;
    }
    .menu-list.close {
        transition: all 0.3s ease-in-out;
        visibility: hidden;
    }
    .menu-icon .fa-times {
        transform: rotate(360deg);
        transition: all .2s ease-in-out;
        float: right;
        display: inline !important;
    }
    .menu-icon .fa-bars {
        transform: rotate(180deg);
        transition: all .2s ease-in-out;
        float: right;
        display: inline !important;
    }
}