* {
  padding: 0;
  box-sizing: border-box;
  font-family: Raleway, sans-serif;
}

element.style {
  opacity: 0;
}

body {
  margin: 0;
}

.comments-component {
  display: block;
  justify-content: center;
  align-items: center;
  background-color: rgba(234, 236, 236, 0.863);
  border-radius: 10px;
  padding: 20px 10px;
  margin: auto;
  max-width: 850px;
}

@media screen and (max-width:850px) {
  .comments-component {
    margin: 10px;
  }
}

.comments-body {
  text-align: center;
}

.comment-card2 {
  max-width: 830px;
  border: 1px solid gray;
  background-color: rgba(221, 224, 224, 0.781);
  padding: 5px;
  margin: 5px;
}

.form-control {
  min-width: 325px;
  min-height: 70px;
  padding: 5px;
  margin: 5px;
}

.comments-button {
  transition-duration: 0.4s;
  background-color: rgb(255, 99, 71);
  padding: 4px;
  border: 1px solid gray;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.comments-button:hover {
  background-color: rgb(219, 88, 65);
  color: white;
}

.container1 {
  background: url("./images/spread.jpg");
  background-color: salmon;
  background-position: center;
  background-size: cover;
  height: 100vh;
  width: 100%;
  position: relative;
  /* overflow: hidden; */
  margin: 0px;
}

.page-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  position: relative;
  z-index: 2;
}

.card > div {
  height: 380px !important;
}

.card {
  height: 500px !important;
}

.slider-control-bottomcenter {
  bottom: -15px !important;
}

.slider-control-bottomcenter > ul > li > button {
  color: salmon !important;
}

img {
  height: 380px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

button[aria-label="previous"],
button[aria-label="next"] {
  visibility: hidden;
  background: salmon !important;
  color: black !important;
}

.page-heading h1 {
  font-size: 5rem;
  color: #fff;
  font-weight: 300;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0px 20px;
  border-radius: 2px;
}
h1{
  font-weight: bold;
}
h2 {
  display: inline-block;
  /* margin-right: 10px !important; */
  text-align: center !important;
}

.register-here {
  padding: 10px;
}

.cardtitle {
  /* display: inline-block; */
  text-align: center;
  margin-right: 10px !important;
}

h2:nth-of-type(2) {
  opacity: 0.7;
  font-size: 1.35rem;
  text-align: center !important;
}
h4{
  font-weight: bold;
}
h5 {
  margin-top: -15px !important;
  margin-left: 2px !important;
  color: lightgray;
}
h5:last-of-type {
  color: darkgray;
}

#deckroot {
  margin: 0;
  padding: 0;
  background: salmon;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

#deckroot > div {
  position: absolute;
  width: 100vw;
  height: 100vh;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
}

#deckroot > div > div {
  padding: 10px;
  background-color: white;
  background-size: auto 85%;
  background-repeat: no-repeat;
  background-position: center center;
  width: 300px;
  max-width: 300px;
  height: 550px;
  max-height: 570px;
  will-change: transform;
  border-radius: 10px;
  box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4),
    0 10px 10px -10px rgba(50, 50, 73, 0.3);
}
circle {
  visibility: hidden;
}


.login-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  /* margin-top: 15%; */
  position: relative;
  top: 25%;
}

.login-card {
  text-align: center;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  opacity: .85;
  position: relative;
}

.login-message {
  margin: 0;
}

.form-control {
  margin: 0;
}

.PageTitle {
  text-align: center;
  padding: 20px;
}

form {
  flex: auto;
  background: none !important;
  text-align: center !important;
}

.myrecipes {
  margin-top: 1rem;
  background-color: salmon;
}

/* .form1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
} */

.choose-file {
  /* background-color: aqua; */
  display: flex;
  /* align-content: center !important; */
  justify-content: center !important;
  width: 100%;
  /* background-color: black; */
  /* text-align: center; */
}

.choose-file > input {
  /* background-color: red; */
  display: none;
}

.choose-file > label {
  /* background-color: red; */
  border: 1px solid black;
  cursor: pointer;
  padding: 10px;
  border-radius: 6px;
  background-color: #dedede;
  box-shadow: 0px 10px 13px -7px #000000, 5px 5px 11px 5px rgba(0,0,0,0);
}


.favrecipes {
  align-content: center;
  margin-top: 1rem, 1rem;
  background-color: salmon;
}

.favcontainer{
  flex: inherit;
  margin: 1.5em;
}

.favcontinner{
  padding: 1.6em;
}

.logout-div {
  top: 15%;
  display: flex;
  justify-content: center;
  position: relative;
}
.logout-button {
  display:flex;
  position: absolute;
  font-weight: 300;
  background-color:tomato;
  border-radius: 6px;
  padding: 6px;
  /* border: 2px;
  border-style: solid 1px black; */
}

.reg-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  position: relative;
  top: 25%;
}

.reg-card {
    text-align: center;
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    opacity: .75;
    position: relative;
    display: inline-block;
}

.create-recipe-button {
  padding: 15px;
}

.close-button-recipe-card {
  display: flex;
  justify-content: center;
  position: relative;
}

.fav-list {
  cursor: pointer;
  text-align: center;
  font-size: 2rem;
  list-style-type: none;
  text-decoration: underline;
  padding: 5px;
}

.title-div {
  display: flex;
  justify-content: center;
  position: relative;
}

.fav-title {
  margin-bottom: 2%;
  border-bottom: solid thin;
  padding-bottom: 5px;
  text-align: center;
  cursor: pointer;
}

.img-div {
  display:flex;
  justify-content: center;
  padding-top: 10px;
}

.fav-image {
  border-radius: 20px;
}

.ingredient-title {
  border-top: thin solid !important;
  padding-top:10px;
  text-align: center;
}

.ingredient-list {
  margin-left: 25px;
  list-style-type: disc !important;
  font-size: 1.1rem;
}

.prep-div {
  padding-top: 10px;
}

.prep-title {
  border-top: thin solid !important;
  padding-top:10px;
  text-align: center;
}

.prep-list {
  font-size: 1.1rem;
  margin-left: 10px;
}

@media screen and (max-width:612px) {
  .fav-image {
    width: 105%;
    height: 105%;
  }
}

.Favline{
border-spacing: 4em;
}
  
.favbutton{
 align-content: flex-end;
}
  
.favcontainer{
  border: solid lightgray;
  border-radius: 2rem;
  background-color: ivory;
}
  
.myform{
  background-color: salmon;
}

.newform{
  align-content: center;
  max-width: 350px !important;
  place-content: center;
  background-color: white;
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 15px;
  padding-top: 15px;
}
